export default [
  {
      title: 'Billing',
      icon: 'DollarSignIcon',
      children: [
        {
          title: 'Invoices',
          route: 'invoices',
        },
        {
          title: 'Transactions History',
          route: 'transactions-history',
        },
      ],
    }
  ];