<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.first_name }} {{ user.last_name }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.image ? appUrl + '/uploads/profile-pictures/' +  user.image : require('@/assets/images/avatars/default-profile-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'profile'}">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";


export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const { appUrl } = $themeConfig.app;
    return {
      user: null,
      appUrl: appUrl,
    };
  },
  methods: {
    logout() {
      this.$http
        .get("/logout")
        .then((response) => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

          // Remove userData from localStorage
          localStorage.removeItem("userData");
          this.$router
            .push({
              name: "login",
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            });
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
  created() {
    this.user = getUserData();
  },
  mounted() {
    let timeout;
    let refresh = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.$http
          .get("/logout")
          .then((_) => {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(
              useJwt.jwtConfig.storageRefreshTokenKeyName
            );

            // Remove userData from localStorage
            localStorage.removeItem("userData");
            localStorage.removeItem("smsUnits");
            this.$router
              .push({
                name: "login",
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Loggged Out Due To Inactivity",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              });
          })
          .catch((error) => {
            for (let err of error.response.data.error) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: err,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }, 10 * 60 * 1000);
    };
    refresh();
    document.addEventListener("click", refresh);
  },
};
</script>
