export default [
  {
    title: 'Reports',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Sender ID',
        route: 'sender-id',
      },
      {
        title: 'SMS History',
        route: 'sms-history',
      },
      {
        title: 'Scheduled SMS',
        route: 'scheduled-sms',
      },
      {
        title: 'Voice History',
        route: 'voice-history',
      },
      {
        title: 'Scheduled Voices',
        route: 'scheduled-voices',
      },
      {
        title: 'Contacts',
        route: 'contacts',
      },
      {
        title: 'Invalid Contacts',
        route: 'invalid-contacts',
      }
    ],
  }
];