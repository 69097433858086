
import dashboard from './dashboard'
import client from './client'
import country from './country'
import billing from './billing'
import report from './report'
import admin from './admin'
import profile from './profile'
import logout from './logout'

export default [
  {
    header:'Pepea SMS Menu'
  },
  ...dashboard,
  ...client,
  ...country,
  ...billing,
  ...report,
  ...admin,
  ...profile,
  ...logout,
];
